.galleryBox {
    display: flex;
    max-width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

.galleryImageBox {
    height: 200px;
    width: 200px;
    margin: 10px;
    transition: all 0.3s ease-in-out;
    &.selected{
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        position: fixed;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 80%;
            max-width: 80%;
        }
    }
}

.gallery {
    background: #f6f8f8;
    padding: 50px 0;

    h1 {
        text-align: center;
        color: rgba(60, 60, 60, 1);
        margin: 0 0 50px;
    }
}